import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";

import "@fontsource/lato/300.css";

import { slide as Menu } from "react-burger-menu";

import "../../static/assets/css/main.css";
import "../../static/assets/css/noscript.css";
import "../../static/assets/css/fontawesome-all.min.css";

import MyForm from "./../pages/kontakt";
import MyFooter from "./../components/footer";
import MyMenu from "../components/menu";

import AccordionComponent from "./../components/accordion";

const faq = () => (
  <>
    <Helmet>
      <title>Lichtbild-Enthusiastin - Claudia Nürnberger</title>
    </Helmet>
    <MyMenu />
    <header></header>
    <main>
      <div className="divided">
        <section className="wrapper style1 content-align-left">
          <div className="inner">
            <h2 className="ueberschriftFAQ">Häufig gestellte Fragen</h2>

            <AccordionComponent className="content-align-left" />
          </div>
        </section>

        <footer className="wrapper style1 align-center randDesktop">
          <MyFooter />
        </footer>
      </div>
    </main>
  </>
);
export default faq;
